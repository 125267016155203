// RewardVideo.js
import React from "react";

const RewardVideo = ({ src, onEnded }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
        zIndex: 99999,
      }}
    >
      <video
        key="reward-video"
        autoPlay
        loop
        muted
        onEnded={onEnded}
        style={{
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          zIndex: 9999,
          position: "absolute",
        }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default RewardVideo;
