import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { campaignsBaseUrl } from "../../api/api";
import axios from "axios";

export const getAllCampaigns = createAsyncThunk(
  "campaigns/getAllCampaigns",
  async (campaingDetails, { rejectWithValue }) => {
    // const token = localStorage.getItem("token");
    try {
      const response = await axios({
        method: "GET",
        url: campaignsBaseUrl + "v1/pack-open",
        campaingDetails,
      });

      if (response.status !== 200) {
        throw new Error("API request failed");
      }
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getSelectedCampaign = createAsyncThunk(
  "campaigns/getSelectedCampaign",
  async (selectedId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: campaignsBaseUrl + "v1/pack-open",
        data: {
          id: selectedId,
        },
      });

      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const getPotionNfts = createAsyncThunk(
  "campaigns/getPotionNfts",
  async (selectedId, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: campaignsBaseUrl + "v1/pack-open/potion",
        data: {
          id: selectedId,
        },
      });

      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const burnPotionNfts = createAsyncThunk(
  "campaigns/burnPotionNfts",
  async ({ selectedId, selectedPotion }, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    console.log("ids", selectedId, selectedPotion);
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: campaignsBaseUrl + "v1/open-pack/open-pack",
        data: {
          id: selectedId,
          potion: selectedPotion,
        },
      });

      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

export const burnPotionNFTS = async (selectedId, selectedPotion) => {
  const token = localStorage.getItem("token");
  console.log("ids", selectedId, selectedPotion);
  try {
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      url: campaignsBaseUrl + "v1/open-pack/open-pack",
      data: {
        id: selectedId,
        potion: selectedPotion,
      },
    });

    if (response.status !== 200) {
      throw new Error("API request failed");
    }

    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

export const getUnmintedNfts = createAsyncThunk(
  "campaigns/getUnmintedNfts",
  async (details, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        url: campaignsBaseUrl + "v1/open-pack/",
        details,
      });

      if (response.status !== 200) {
        throw new Error("API request failed");
      }

      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  campaigns: [],
  selectedCampaign: null,
  potionnfts: null,
  burnpotionnfts: null,
  unmintednfts: null,
  unmintednftsloading: false,
  potionnftsloading: false,
  burnpotionnftsloading: false,
  burnpotionnftserror: null,
  loading: false,
  error: null,
};

const collectionSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    clearSelectedCampaign: (state) => {
      state.selectedCampaign = null;
      state.error = null;
    },
    clearPotionError: (state) => {
      state.burnpotionnftserror = null;
    },
  },
  extraReducers: {
    [getAllCampaigns.pending]: (state) => {
      state.loading = true;
    },
    [getAllCampaigns.fulfilled]: (state, action) => {
      state.loading = false;
      state.campaigns = action.payload;
    },
    [getAllCampaigns.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getSelectedCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getSelectedCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedCampaign = action.payload;
    },
    [getSelectedCampaign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getPotionNfts.pending]: (state) => {
      state.potionnftsloading = true;
    },
    [getPotionNfts.fulfilled]: (state, action) => {
      state.potionnftsloading = false;
      state.potionnfts = action.payload;
    },
    [getPotionNfts.rejected]: (state, action) => {
      state.potionnftsloading = false;
      state.error = action.payload;
    },
    [burnPotionNfts.pending]: (state) => {
      state.burnpotionnftsloading = true;
    },
    [burnPotionNfts.fulfilled]: (state, action) => {
      state.burnpotionnftsloading = false;
      state.burnpotionnfts = action.payload;
    },
    [burnPotionNfts.rejected]: (state, action) => {
      state.burnpotionnftsloading = false;
      state.burnpotionnftserror = action.payload;
    },
    [getUnmintedNfts.pending]: (state) => {
      state.unmintednftsloading = true;
    },
    [getUnmintedNfts.fulfilled]: (state, action) => {
      state.unmintednftsloading = false;
      state.unmintednfts = action.payload;
    },
    [getUnmintedNfts.rejected]: (state, action) => {
      state.unmintednftsloading = false;
      state.error = action.payload;
    },
  },
});
export const { clearSelectedCampaign, clearPotionError } =
  collectionSlice.actions;

export default collectionSlice.reducer;
