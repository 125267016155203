import * as React from "react";

import {
  campaignsBaseUrl,
  imageURL,
  imgUrl,
  imgUrlLogo,
} from "../../../services/api/api";
import {
  Paper,
  Typography,
  Box,
  Card,
  Grid,
  colors,
  Tooltip,
} from "@mui/material";

const CampaignsCard = ({ data, onConfirm }) => {
  let height = 305;
  return (
    <>
      <Card
        sx={{
          background: "black",
          minWidth: height,
          maxWidth: 345,
          borderRadius: 4,
          cursor: "pointer",
          transition: "transform 0.3s",
          boxShadow: "0 0 10px rgba(125, 60, 224, 0.4)",

          ":hover": {
            transform: "scale(1.05)",
            boxShadow: "0 0 2 0px rgba(125, 60, 224, 0.5)",
          },
          border: "solid 2px #AF22C8 ",
        }}
        onClick={() => onConfirm(data)}
      >
        <Paper sx={{ background: "black" }}>
          <Box
            sx={{
              pt: 1,

              justifyContent: "center",
              textAlign: "left",
            }}
          >
            <Box sx={{ px: 1 }}>
              <img
                style={{
                  width: "100%",
                  padding: 0,
                }}
                // src={
                //   `${campaignsBaseUrl}${data?.thumbnail}` ||
                //   "https://deluxapi.thecbt.live/uploads/collections/1723297557803-287362015.webp"
                // }
                src={`${imgUrl}${data?.background}`}
                title={data?.name}
                alt={data?.name}
                resizeMode="contain"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                px: 1,
              }}
            >
              <Box>
                <Typography
                  variat="h6"
                  sx={{
                    color: "white",
                    fontSize: 13,
                    fontWeight: 600,
                    py: 1,
                    color: "#AF22C8",
                    fontFamily: "goodTime",
                  }}
                >
                  {data?.name
                    ? data?.name?.length > 30
                      ? `${data?.name?.slice(0, 30)}........`
                      : data?.name
                    : "N/A"}
                </Typography>
              </Box>
            </Box>
            <Tooltip title={data?.description} placement="top">
              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1.5em",
                  maxHeight: "3em",
                  color: "white",
                  fontFamily: "goodTime",
                  paddingX: "5px",
                  fontSize: "10px",
                  marginBottom: "10px",
                  marginX: "5px",
                }}
              >
                {data?.description}
              </Typography>
            </Tooltip>
          </Box>
        </Paper>
      </Card>
    </>
  );
};

export default CampaignsCard;
