import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  burnPotionNFTS,
  burnPotionNfts,
  clearPotionError,
  clearSelectedCampaign,
  getPotionNfts,
  getSelectedCampaign,
} from "../../services/redux/CampaignsReducer/CampaignsReducer";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SimpleSliderCampaigns from "./SimpleSliderCampaigns";
import { imgUrlLogo } from "../../services/api/api";
import BackgroundVideo from "./BackgroundVideo";
import RewardVideo from "./RewardVideo";
import ClaimPacksModal from "../../components/features/Campaigns/ClaimPacksModal";
import ErrorModal from "./ErrorModal";

const RewardBags = () => {
  const [selectedPotion, setSelectedPotion] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [showClaimPackModal, setshowClaimPackModal] = useState(false);
  const [burnpotionnftserror, setburnpotionnftserror] = useState(null);
  const { selectedCampaign, loading: selectedCampaignLoading } = useSelector(
    (state) => state.campaigns
  );

  const { potionnfts: potionNftsData, potionnftsloading } = useSelector(
    (state) => state.campaigns
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    dispatch(getSelectedCampaign(params.id));
    dispatch(getPotionNfts(params.id));
  }, [params.id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const backgroundVideoSrc = require("../../assets/bgvideo.mp4");
  const imageLogo = `${imgUrlLogo}${selectedCampaign?.data?.logo}`;
  const rewardVideoSrc =
    selectedCampaign?.data?.videoType === 1
      ? require("../../assets/RewardBags/Potion-Burn.mp4")
      : require("../../assets/RewardBags/Reward-Nft.mp4");

  const handleBurnPotions = async () => {
    setShowVideo(true);
    if (selectedPotion) {
      const response = await burnPotionNFTS(
        parseInt(params.id),
        selectedPotion?.nftokenID
      );
      if (response?.status) {
        setShowVideo(false);
        setshowClaimPackModal(true);
        setburnpotionnftserror(null);
      } else {
        setshowClaimPackModal(false);
        setburnpotionnftserror(response?.data?.message);
        setShowVideo(false);
      }
    }
  };

  if (selectedCampaignLoading || potionnftsloading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", minHeight: "80vh" }}
      >
        <CustomLoader width="60%" text="Please wait ..." />
      </Box>
    );
  }

  if (showVideo) {
    return <RewardVideo src={rewardVideoSrc} onEnded={() => {}} />;
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Background Video */}
      <BackgroundVideo src={backgroundVideoSrc} isVisible={!showVideo} />

      <Grid
        container
        spacing={2}
        sx={{
          position: "relative",
          zIndex: 5,
          backgroundColor: "rgba(0,0,0,0.8)",
        }}
      >
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}>
            <Button
              size="large"
              sx={{ color: "white", pl: 4 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="medium" sx={{ color: "white" }} />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={8}>
          {!selectedCampaignLoading && (
            <Box sx={{ pt: 2, pb: 2 }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box sx={{ p: 1 }}>
                    <img
                      style={{
                        width: "100%",
                        height: "110px",
                        objectFit: "contain",
                      }}
                      src={imageLogo}
                      alt="Campaign Logo"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box sx={{ py: 2 }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "white",
                        textAlign: "left",
                        fontFamily: "goodTime",
                        mt: 1,
                      }}
                      fontSize="small"
                    >
                      {selectedCampaign?.data?.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={2} />
      </Grid>

      <Divider
        variant="middle"
        sx={{ mt: 0, backgroundColor: "#AF22C8", mx: 4, mb: 4 }}
      />

      {potionnftsloading ? (
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {potionNftsData?.data?.length === 0 ? (
            <Typography
              sx={{
                fontWeight: 600,
                color: "white",
                textAlign: "center",
                fontFamily: "goodTime",
                zIndex: 900,
                position: "relative",
              }}
              fontSize="large"
            >
              {"No items found"}
            </Typography>
          ) : (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Box sx={{ width: "80%", margin: "0 auto", display: "block" }}>
                <SimpleSliderCampaigns
                  images={potionNftsData?.data}
                  selected={selectedPotion}
                  setselected={setSelectedPotion}
                />
              </Box>
            </Box>
          )}
        </>
      )}

      {!potionnftsloading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "30px",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              width: "100px",
              textTransform: "none",
              bgcolor: "#BF40BF",
              color: "white",
              borderRadius: "30px",
              padding: "12px",
              fontSize: "18px",
              fontFamily: "goodTime",
              "&:hover": { bgcolor: "#BF40BF" },
            }}
            onClick={handleBurnPotions}
            disabled={!selectedPotion}
          >
            Open
          </Button>
        </Box>
      )}
      {showClaimPackModal && !burnpotionnftserror ? (
        <ClaimPacksModal
          openModal={showClaimPackModal}
          onClose={() => {
            navigate("/packs");
            setburnpotionnftserror(null);
            setshowClaimPackModal(false);
            setShowVideo(false);
          }}
        />
      ) : burnpotionnftserror ? (
        <ErrorModal
          error={burnpotionnftserror}
          openModal={true}
          onClose={() => {
            navigate("/packs");
            clearPotionError();
            setshowClaimPackModal(false);
            setShowVideo(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default RewardBags;
