import React from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "../../shared/customLoader/customLoader";
import { useNavigate } from "react-router-dom";

function UsdModal({ openModal, onClose, imageUrl, nft, loading, noOfItems }) {
  const navigate = useNavigate();

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "80vh",
            }}
          >
            <CustomLoader width={"60%"} text={"Waiting xaman approval ... "} />
          </Box>
        </Box>
      ) : (
        <Modal
          componentsProps={{
            backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } },
          }}
          open={openModal}
          onClose={onClose}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              direction: "column",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                bgcolor: "black",
                border: "solid 2px #af22c8",
                borderRadius: 4,
                color: "white",
                p: { xs: 1, sm: 2, md: 4, lg: 4 },
                width: { xs: "90vw", sm: "70vw", md: "60vw", lg: "40vw" },
                textAlign: "center",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ py: 1 }}
              >
                <Box />
                <Box
                  justifyContent={"end"}
                  sx={{
                    mt: { xs: -1, sm: -2, md: -4, lg: -4 },
                    mr: { xs: 1, sm: 0, md: -2, lg: -2 },
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "none",
                      color: "white",
                      border: "2px solid #af22c8",
                      borderRadius: "8px",
                      padding: "2px",
                    }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <Typography sx={{ fontFamily: "goodTime", fontSize: 14, mb: 1 }}>
                🎉 New NFT Minting Request Generated! 🎉
              </Typography>
              <Typography
                sx={{
                  fontFamily: "goodTime",
                  fontSize: 9,
                  color: "#f1ed00",
                  pt: 1,
                }}
              >
                Once your {noOfItems > 1 ? "nfts are" : "nft is"} minted, you
                can claim your Newly minted {noOfItems > 1 ? "NFTs" : "NFT"} by
                going to the transfer section.
              </Typography>

              <Button
                sx={{
                  backgroundColor: "#AF22C8",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#AF22C8",
                  },
                  borderRadius: "20px",
                  textTransform: "none",
                  m: { xs: 1, sm: 2 },
                  px: 2,
                }}
                onClick={() => {
                  navigate("/nfts/transfer");
                }}
              >
                <Typography sx={{ fontFamily: "goodTime" }}>
                  Go to Transfers
                </Typography>
              </Button>
            </Paper>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UsdModal;
