import React, { useState, useEffect } from "react";
import { Button, Box, Grid, Typography, Divider } from "@mui/material";
import MediaCard from "../../components/features/CategorryCard/Card";
import { axiosRequestAuth } from "../../services/api/api";

// import styles from "./Mint.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  setCollectionId,
  setTaxonId,
  setCollectionName,
} from "../../services/redux/CollectionReducer/CollectionReducer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomLoader from "../../components/shared/customLoader/customLoader";
import UserCollectionCard from "../../components/features/UserCollectionCard/UserCollectionCard";
import ScrollToTopOnMount from "../../utils/ScrollToTop/automaticScrollToTop/automaticScrollToTop";

const containerStyle = {
  position: "relative",
  width: "100%",
  minHeight: "100vh",
  background:
    "linear-gradient(225deg, #731686 1%, transparent 10%, transparent 80%, #af22c8 100%)", // Linear gradient background
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  paddingBottom: "20px",
  overflowX: "hidden",
};
const contentStyle = {
  position: "relative",
  top: "0px",
  width: "100%",
};

const Collection = () => {
  const txtcolor = "#f1f1f1";
  const { categoryId } = useSelector((state) => state.landingPage);
  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState(false);
  const [orderList, setOrderList] = useState(null);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const param = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromPacks = queryParams.get("fromPacks") === "true";
  const fromBuyPacks = queryParams.get("fromBuyPacks") === "true";

  const getCategoriesById = async (id) => {
    setLoading(true);
    try {
      const response = await axiosRequestAuth(
        "get",
        `category/collection/${id}`
      );
      // console.log(response.data.data, "response");

      setCategoryName(response?.data?.data?.category?.name);
      setLoading(false);
      setCollectionData(response?.data?.data?.collections);
      setOrderList(response?.data?.data?.order?.sorted_ids);
      // toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const orderedRows = orderList
    ?.map((orderId) => collectionData?.find((row) => row?.id === orderId))
    ?.filter((item) => item !== undefined);

  const handleData = (e) => {
    dispatch(setCollectionId(e.id));
    dispatch(setCollectionName(e.name));
    dispatch(setTaxonId(e.taxon_id));

    if (fromBuyPacks) {
      if (e.collection_type === "G") {
        navigate(`/game-collections/details/${e.id}`);
      } else if (e.collection_type === "B") {
        navigate(`/usd-collections/details/${e.id}`);
      } else {
        navigate(`/category-collection/${e.vanity_name}`);
      }
    } else {
      navigate(`/category-collection/${e.vanity_name}`);
    }
  };
  useEffect(() => {
    getCategoriesById(fromBuyPacks ? 67 : fromPacks ? 66 : categoryId);
  }, [fromBuyPacks, fromPacks]);

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Box>
          <ScrollToTopOnMount />
          <Grid container spacing={2}>
            <Grid item lg={2}>
              {" "}
              {/* <Box
                sx={{ display: "flex", justifyContent: "start", pt: 3, ml: 1 }}
              > */}
              {/* <Button
                  size="large"
                  sx={{ color: txtcolor }}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIcon fontSize="large" sx={{ color: txtcolor }} />
                </Button> */}
              {/* </Box>{" "} */}
            </Grid>
            <Grid item lg={8}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pt: 4, pb: 4 }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: txtcolor,
                    fontWeight: 600,
                    fontFamily: "goodTime",
                  }}
                >
                  {fromPacks ? "Pack NFTs" : "Collections"}
                </Typography>
              </Box>
            </Grid>
            <Divider
              variant="middle"
              sx={{ mt: 1, backgroundColor: "#AF22C8", mx: 6, width: "100%" }}
            />
            <Grid item lg={2}>
              <Box
                sx={{ display: "flex", justifyContent: "center", pr: 4 }}
              ></Box>
            </Grid>
          </Grid>
          {!loading ? (
            <>
              {!collectionData?.length ? (
                <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
                  <Typography
                    variant="h6"
                    sx={{ color: "white", fontFamily: "goodTime" }}
                  >
                    {fromPacks ? "No Packs Found!" : "No Collection Found!"}{" "}
                  </Typography>
                </Box>
              ) : (
                <Grid
                  container
                  justifyContent={
                    collectionData?.length < 4 ? "center" : "flex-start"
                  }
                  spacing={2}
                  sx={{ pt: 4, px: 4 }}
                >
                  {collectionData &&
                    orderedRows?.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        lg={3}
                        sm={6}
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          py: 4,

                          pb: 2,
                        }}
                      >
                        <UserCollectionCard
                          data={item}
                          onConfirm={handleData}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          ) : (
            <Box height={"80vh"} sx={{ pt: 10 }}>
              <CustomLoader width="15%" text={"Please wait ..."} />
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

export default Collection;
{
  /* <Divider
variant="middle"
sx={{ mt: 2, backgroundColor: "#AF22C8", mx: 4, mb: 4 }}
/> */
}
